// @ts-nocheck
import { connect } from "react-redux";
import { useTranslation, withTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import AccountBoxRounded from "@material-ui/icons/AccountBoxRounded";
import SchoolRounded from "@material-ui/icons/SchoolRounded";
import BusinessCenterRounded from "@material-ui/icons/BusinessCenterRounded";
import PersonAddRounded from "@mui/icons-material/PersonAddRounded";
import EmojiEventsRounded from "@material-ui/icons/EmojiEventsRounded";
import Check from "@mui/icons-material/Check";
import StarIcon from "@mui/icons-material/Star";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { jobDetailType } from "src/components/common/MainContent/JobResults/common";
import { useState, useEffect, useMemo } from "react";
import { getAccessToken } from "axios-jwt";
import { graphqlRequest } from "src/services/GraphQL/GraphQL";
import { SEARCH_JOBS_WITH_VIEWS } from "src/services/GraphQL/graph.query";
import clsx from "clsx";
import { Link, useHistory } from "react-router-dom";
import {
  BASIC_INFO_PAGE,
  EDU_SPEC_PAGE,
  WORK_EXP_PAGE,
  SKILL_CHECKLIST_OVERVIEW_PAGE,
  REFERENCES_PAGE,
} from "src/router/routes";
import config from "src/config";
import { Skeleton } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { RecommendedJobsSourceTypes } from "src/types/receommendedJobsSource";
import ProgressBar from "./ProgressBar";
import styles from "./IncompleteProfile.module.scss";
import JobSlider from "../../job-card/slider/JobSlider";
import JobCardList from "../../job-card/list/JobCardList";
import { JobCardProps } from "../../job-card/JobCard";
import { getRandomBackgroundImage } from "../background";
import "./override.scss";

interface SearchJobsWithViews {
  searchJobsWithViews: {
    embedded: {
      results: jobDetailType[];
    };
  };
}

interface Step {
  step: number;
  icon?: JSX.Element;
  name?: string;
  estimatedTime?: number;
  isComplete: boolean;
  url?: string;
}

interface IncompleteProfileProps {
  completedSteps: {
    [key: string]: boolean;
  };
  useProfileIframe: boolean;
  firstName?: string;
  onboardingInfo: any;
  companyId: any;
  atsBullhornCandidateId: any;
  bhId: any;
}

const IncompleteProfile = ({
  completedSteps,
  useProfileIframe,
  firstName,
  onboardingInfo,
  companyId,
  atsBullhornCandidateId,
  bhId
}: IncompleteProfileProps) => {
  const { t } = useTranslation();

  const isLoading = firstName == null;
  const history = useHistory();

  const requiredStepsAmount = useProfileIframe ? 4 : 5;
  const completedStepsAmount = completedSteps != null ? Object.keys(completedSteps).length : 0;

  const randomBackgroundImage = useMemo(() => {
    return `linear-gradient(0deg, rgba(0, 51, 102, 0.60) 0%, rgba(0, 51, 102, 0.60) 100%), url("${getRandomBackgroundImage()}")`;
  }, []);

  // Quick Apply Views
  const [jobs, setJobs] = useState<jobDetailType[]>([]);
  const skeletonCardOptions = Array.from({ length: 4 }).map((_) => ({
    job: undefined,
    contentOptions: {
      compact: true,
    },
  } as JobCardProps));
  const jobCardOptions = jobs.map((job) => ({
    job,
    contentOptions: {
      secondaryInfo: "views",
      compact: true,
    },
    campaignCode: "Job Views",
    sourceType: "Homepage",
    jobsSource: RecommendedJobsSourceTypes.NONE,
  } as JobCardProps)) ?? [];

  const [isJobsLoading, setIsJobsLoading] = useState<boolean>(true);
  const [hasFetchedJobs, setHasFetched] = useState<boolean>(false);

  const { specialtiesAndEducation: onboardingSpecialty } = onboardingInfo;

  const authToken = getAccessToken();

  const isQuickApplyViewsEnabled = config.Dashboard_ADVERTISEMENT === "true" && (jobs.length > 0 || isJobsLoading);

  useEffect(() => {
    const getJobs = async () => {
      const response = await graphqlRequest<SearchJobsWithViews>(SEARCH_JOBS_WITH_VIEWS, {
        professions: onboardingSpecialty?.specialty?.profession || null,
        specialties: onboardingSpecialty?.specialty?.primarySpecialty || null,
        companyId: Number(companyId),
        candidateId: 1787931,
        includeQuickApplyEligibility: false,
        candidateInformation: {},
        isProfileComplete: false
      }, authToken);

      setJobs(response?.searchJobsWithViews?.embedded?.results ?? []);
    }

    if (isQuickApplyViewsEnabled && (atsBullhornCandidateId || bhId) && companyId && onboardingSpecialty && hasFetchedJobs === false) {
      setHasFetched(true);

      getJobs().then(() => {
        setIsJobsLoading(false);
      }).catch((error) => {
        console.error("Error:", error);
      });
    }
  }, [(atsBullhornCandidateId || bhId), companyId, onboardingSpecialty, isQuickApplyViewsEnabled]);

  const quickApplyViews = isQuickApplyViewsEnabled && (
    <div className={clsx(styles.quickApplyContainer, "quickApplyContainer")}>
      <div className={clsx(styles.quickApplyTitle, styles.compact)}>{t("dashboard.jobsViews.ProfileIncMobile")}</div>
      <div className={clsx(styles.quickApplyTitle, styles.regular)}>{t("dashboard.jobsViews.profileIncomplete")}</div>

      <div className={styles.jobSlider}>
        <JobSlider
          jobCardOptions={isJobsLoading ? skeletonCardOptions : jobCardOptions}
          settings={{
            xs: {
              slidesToShow: 1.3,
              swipe: !isJobsLoading
            }
          }}
        />
      </div>

      <div className={styles.jobCardList}>
        <JobCardList
          jobCardOptions={isJobsLoading ? skeletonCardOptions : jobCardOptions}
        />
      </div>
    </div>
  );

  if (isLoading) {
    return (
      <div className={styles.main} style={{
        backgroundImage: randomBackgroundImage,
      }}>
        <div className={styles.sub}>
          <div className={styles.compactProgressContainer}>
            <div className={styles.greeting}>
              <Skeleton animation="wave" variant="text" width={200} height={50} sx={{ margin: "auto" }} />
            </div>
            <ProgressBar progress={undefined} />
            <div className={styles.completeButton}>
              <Skeleton
                animation="wave"
                height={80}
                sx={{ margin: "auto", borderRadius: "15px 15px 15px 0px", maxWidth: "350px" }}
              />
            </div>
          </div>
          <div className={styles.progressContainer}>
            <div className={styles.progressBar}>
              <ProgressBar progress={undefined} />
            </div>
            <div className={styles.greetingAndCurrentProgress}>
              <div className={styles.greeting}>
                <Skeleton animation="wave" variant="text" width={500} height={50} />
              </div>
              <div className={styles.stepper}>
                {Array.from({ length: 5 }).map((_) => (
                  <div key={uuidv4()} className={clsx(styles.step, styles.skeletonStep)}>
                    <div className={styles.stepIcon}>
                      <Skeleton variant="circular" width={20} height={20} />
                    </div>
                    <div className={styles.stepName}>
                      <Skeleton variant="text" width={100} height={25} />
                    </div>
                  </div>
                ))}
                <div className={styles.step}>
                  <div className={styles.stepIcon}>
                    <Skeleton variant="circular" width={25} height={25} />
                  </div>
                </div>
              </div>
              <div className={styles.pickupWhereYouLeftOff}>
                <Skeleton animation="wave" variant="text" width={200} height={25} />
              </div>
            </div>
          </div>

          {quickApplyViews}
        </div>
      </div>
    );
  }

  const profileProgress = Math.round((completedStepsAmount / requiredStepsAmount) * 100);

  const steps: Step[] = [
    {
      step: 1,
      icon: <AccountBoxRounded />,
      name: "Basic Information",
      estimatedTime: 2,
      isComplete: completedSteps?.[0] === true,
      url: BASIC_INFO_PAGE
    },
    {
      step: 2,
      icon: <SchoolRounded />,
      name: "Specialties & Education",
      estimatedTime: 3,
      isComplete: completedSteps?.[1] === true,
      url: EDU_SPEC_PAGE
    },
    {
      step: 3,
      icon: <BusinessCenterRounded />,
      name: "Work Experience",
      estimatedTime: 5,
      isComplete: completedSteps?.[2] === true,
      url: WORK_EXP_PAGE
    },
    {
      step: 4,
      icon: <PersonAddRounded />,
      name: "References",
      estimatedTime: 2,
      isComplete: completedSteps?.[3] === true,
      url: REFERENCES_PAGE
    },
    {
      step: 5,
      icon: <EmojiEventsRounded />,
      name: "Skills Checklist",
      estimatedTime: 15,
      isComplete: completedSteps?.[4] === true,
      url: SKILL_CHECKLIST_OVERVIEW_PAGE
    },
    {
      step: 6,
      icon: <StarIcon />,
      isComplete: false
    }
  ];

  const leftOffOnStep = steps.find(step => !step.isComplete);

  return (
    <div id="dashboard-banner" className={styles.main} style={{
      backgroundImage: randomBackgroundImage,
    }}>
      <div className={styles.sub}>
        <div className={styles.compactProgressContainer}>
          <div className={styles.greeting}>{t("dashboard.banner.dashProfileBannerTxt1")} {firstName}!</div>
          <ProgressBar progress={profileProgress} />
          <div className={styles.completeButton}>
            <Button onClick={ () => { history.push(leftOffOnStep?.url)} } variant="contained"> {t("dashboard.banner.completeMyProfile")}  </Button>
          </div>
        </div>
        <div className={styles.progressContainer}>
          <div className={styles.progressBar}>
            <ProgressBar progress={profileProgress} />
          </div>
          <div className={styles.greetingAndCurrentProgress}>
            <div className={styles.greeting}>
              {t("dashboard.banner.dashProfileBannerTxt1")} {firstName}!
              {" "} {t("dashboard.banner.dashProfileBannerTxt2")}
            </div>
            <div className={styles.stepper}>
              {steps.map((step) => {
                const innerStep = (
                  <>
                    {step.icon && (
                      <div className={styles.stepIcon}>
                        {step.isComplete && <Check />}
                        {!step.isComplete && step.icon}
                      </div>
                    )}
                    {step.name && (
                      <div className={styles.stepName}>{step.name}</div>
                    )}
                    {step.estimatedTime && (
                      <div className={styles.stepEstimatedTime}>
                        -{step.estimatedTime} mins
                      </div>
                    )}
                  </>
                );
                const className = clsx(styles.step, step.isComplete && styles.stepComplete, !step.name && styles.iconOnlyStep);

                if (step.url) {
                  return (
                    <Link key={uuidv4()} to={step.url} className={className}>
                      {innerStep}
                    </Link>
                  )
                }

                return (
                  <div key={uuidv4()} className={className}>
                    {innerStep}
                  </div>
                )
              })}
            </div>
            {leftOffOnStep?.url != null && (
              <div className={styles.pickupWhereYouLeftOff}>
                <Link to={leftOffOnStep.url}>{t("dashboard.banner.pickUpMessage")} <ChevronRightIcon /></Link>
              </div>
            )}
          </div>
        </div>

        {quickApplyViews}
      </div>
    </div>
  );
}

IncompleteProfile.defaultProps = {
  firstName: null
};

const mapStateToProps = (state) => ({
  completedSteps: state.onBoarding.completedSteps,
  useProfileIframe: state.auth.useProfileIframe,
  firstName: state.auth.firstName,
  onboardingInfo: state.onBoarding,
  companyId: state.company.company.id,
  atsBullhornCandidateId: state.candidate.atsBullhornCandidateId,
  bhId: state.candidate.bullhornIdFromBullhorn
});

export default connect(mapStateToProps)(withTranslation()(IncompleteProfile));
