import { useEffect, useState } from "react";
import AWS from "aws-sdk";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import config from "src/config";
import { TextField } from "@mui/material";
import { getCompanyDetails, saveTokenInLocalStorageAndFillState } from "src/redux/auth/action";
import { HOME, VERIFY_PASSWORD } from "src/router/routes";
import jwt from "jwt-simple";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { isMobile } from "react-device-detect";
import { graphqlRequest as request } from "src/services/GraphQL/GraphQL";
import { useTranslation } from "react-i18next";
import Dynatrace from "src/services/Dynatrace";
import { GET_COGNITO_USER, GET_USER_AUTH_STATUS, SET_USER_AUTH_EVENT } from "src/services/GraphQL/graph.query";
import { callWithRetry, getCognitoUserAttributeValue } from "src/services/CommonUtility";
import { eventCreatedBy, eventName, VerifyAuthViewType } from "src/types/verifyAuthViewType";
import { useMutation } from "@apollo/client";
import styles from "./VerifyOTP.module.scss";
import LowerFooter from "../../Footer/LowerFooter/LowerFooter";
import MobileAppQR from "../MobileAppQR";
import VerifyAuthView from "./VerifyAuthView";

interface IVerifyOTPProps {
  pullUserInformation: (idToken: any, refreshToken: any, companyId: any, path: any, isOTP?: Boolean) => any;
}

type LocationState = { email: string, isUserPresentInCognito: boolean, isPasswordPresent: boolean, isEmailVerified: boolean, phoneNumber: string };

const VerifyOTPForm = (props: IVerifyOTPProps) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [stateFromSignInPage] = useState(history.location.state as LocationState ?? JSON.parse(localStorage.getItem("stateFromSignInPage") ?? "{}") as LocationState);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isUserPresent, setIsUserPresent] = useState(false);
  const [isPasswordPresent, setIsPasswordPresent] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [saveTheUserAuthEvent ] = useMutation(SET_USER_AUTH_EVENT);
  const [otp, setOtp] = useState("");
  const [session, setSession] = useState("");
  const [maxAttempts, setMaxAttempts] = useState(Number);
  const [attemptsLeft, setAttemptsLeft] = useState(Number);
  const [fieldMessage, setFieldMessage] = useState({ isError: false, message: "" });
  const [isResendOTPEnabled, setIsResendOTPEnabled] = useState(true);
  const [secondsRemaining, setSecondsRemaining] = useState(0);
  const [cognitoAPILoading, setCognitoAPILoading] = useState(false);
  const [noOfTimesOTPResent, setNoOfTimesOTPResent] = useState(-1);
  const [displayGettingUserLoader ,setDisplayGettingUserLoader ] = useState(false);
  const [displayAccountLocked , setDisplayAccountLocked ] = useState(false);
  const [displayTrySigningInLater , setDisplayTrySigningInLater ] = useState(false);
  const [jwtToken] = useState((history.location.pathname.split("/")[2]) || "");

  const cognitoConfigurations = config.cognito;
  const enableMobileAppInfo = config.ENABLE_MOBILE_APP_INFO === "true";

  AWS.config.update({
    region: cognitoConfigurations.region,
  });

  const cognito = new AWS.CognitoIdentityServiceProvider(AWS.config);

  useEffect(() => {
    let timer;
    if (!isResendOTPEnabled) {
      timer = setInterval(() => {
        setSecondsRemaining((prevSeconds) => {
          if (prevSeconds > 0) {
            return prevSeconds - 1;
          }
          setIsResendOTPEnabled(true);
          clearInterval(timer);
          return 0;
        });
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [isResendOTPEnabled, secondsRemaining]);

  useEffect(() => {
    if(stateFromSignInPage) {
      localStorage.setItem("stateFromSignInPage", JSON.stringify(stateFromSignInPage));
    }
  }, []);

  useEffect(() => {
    let emailFromUrl = "";
    if (jwtToken) {
      localStorage.removeItem("stateFromSignInPage");
      try {
        const data = jwt.decode(jwtToken, `${config.JWT_REDIRECT_SECRET_KEY}`);
        emailFromUrl = data.email;
      } catch (e) {
        setFieldMessage({ isError: true, message: "No Email address given" });
      }
    } else if (stateFromSignInPage) {
      emailFromUrl = stateFromSignInPage.email;
      setIsUserPresent(stateFromSignInPage.isUserPresentInCognito);
      setIsEmailVerified(stateFromSignInPage.isEmailVerified);
      setIsPasswordPresent(stateFromSignInPage.isPasswordPresent);
      setPhoneNumber(stateFromSignInPage.phoneNumber);
    }

    const getUser = async (userEmail): Promise<boolean> => {
      let userFound = false;
      await request(GET_COGNITO_USER, {
        email: userEmail,
      })
        .then((apiResponse) => {
          const userResponse = apiResponse?.getCognitoUser?.user;
          if (apiResponse?.getCognitoUser?.statusCode === 200) {
            setIsUserPresent(userResponse?.Username !== "");
            userFound = true;
            setIsPasswordPresent(
              getCognitoUserAttributeValue(
                "custom:passwordPresent",
                userResponse?.UserAttributes
              ) === "true"
            );
            setIsEmailVerified(
              getCognitoUserAttributeValue(
                "email_verified",
                userResponse?.UserAttributes
              ) === "true"
            );
            setPhoneNumber(
              `${getCognitoUserAttributeValue("phone_number", userResponse?.UserAttributes)}`
            );
            setDisplayTrySigningInLater(false);
          } else if (apiResponse?.getCognitoUser?.statusCode === 404) {
            setDisplayTrySigningInLater(true);
          }
        })
        .catch((error) => {
          userFound = false;
          if (error.code === "UserNotFoundException") {
            setFieldMessage({ isError: true, message: "No user found with given email" });
          } else if (error.code === "NetworkingError") {
            setFieldMessage({ isError: true, message: "Please check your Internet" });
          }
        });

        return Promise.resolve(userFound);
    };

    if (emailFromUrl) {
      setEmail(emailFromUrl);
      if (!isUserPresent) {
        setDisplayGettingUserLoader(true);
        callWithRetry({
          noOfRetry: 5,
          retryDelay: 2000,
          cb: getUser,
          params: { email: emailFromUrl },
        }).then((isSuccess) => {
          if(!isSuccess) {
            setDisplayGettingUserLoader(false);
          }
        });
      }
    } else {
      setFieldMessage({ isError: true, message: "No Email address given" });
    }
  }, []);

  const identityUserInDynatrace = (tokenObject) => {
    try {
      if (tokenObject && !Dynatrace().disable()) {
        Dynatrace().identify(email.trim());
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(`Error while identifying user in dynatrace: ${err}`);
    }
  }

  const initiateAuth = () => {
    const authParams: AWS.CognitoIdentityServiceProvider.InitiateAuthRequest = {
      AuthFlow: "CUSTOM_AUTH",
      ClientId: cognitoConfigurations.clientId || "",
      AuthParameters: {
        USERNAME: email,
      },
    };

    setNoOfTimesOTPResent(noOfTimesOTPResent + 1);
    setFieldMessage({ isError: false, message: "" });

    cognito
      .initiateAuth(authParams)
      .promise()
      .then((data) => {
        setSession(data.Session || "NO_SESSION_FOUND");
        if (data.ChallengeParameters && data.ChallengeParameters.attemptsLeft) {
          setMaxAttempts(Number(data?.ChallengeParameters?.maxAttempts));
          setAttemptsLeft(Number(data.ChallengeParameters.attemptsLeft));
        }
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log("Error while initiating user auth :", err);
      });
  };

  useEffect(() => {
    const getUserAuthStatus = async () => {
      setDisplayGettingUserLoader(true);
      await request(GET_USER_AUTH_STATUS, {
        email,
      })
        .then((apiResponse) => {
          if(apiResponse?.getUserStatusAndAuthEvents?.status === "Blocked") {
              setDisplayAccountLocked(true);
          } else {
            initiateAuth();
          }
          setDisplayGettingUserLoader(false);
        })
    }
    if (isUserPresent) {
      getUserAuthStatus();
    }
  }, [isUserPresent]);

  const getMaskedEmailAndMobile = () => {
    const [localPart, domain] = email?.split("@");
    const maskedEmail = `${"•".repeat((localPart.length || 1) - 1)}${localPart.slice(-1)}@${domain}`;

    const formatPhoneNumber = phoneNumber?.replace("+1", "")?.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    const maskedMobile = formatPhoneNumber?.replace(/(\d{3})-(\d{3})-(\d{4})/, "•••-•••-$3");

    const showCodeSentOnMobile = isEmailVerified && maskedMobile;


    return (
      <div>
        { maskedEmail && <span className={styles.encodedEmail}>
          {" "}
          {maskedEmail}
        </span> }
        {showCodeSentOnMobile ? <span> and </span> : <span>. <br /></span>}
        {showCodeSentOnMobile && <span className={styles.encodedEmail}>
          {" "}
          {maskedMobile}. <br />
        </span>}
      </div>
    )
  }

  const submitOTP = () => {
    if (otp.length !== 6) {
      setFieldMessage({ isError: true, message: t("passwordLessAuth.enter6DigitCode") });
    } else {
      setCognitoAPILoading(true);
      const params: AWS.CognitoIdentityServiceProvider.RespondToAuthChallengeRequest = {
        ChallengeName: "CUSTOM_CHALLENGE",
        ClientId: cognitoConfigurations.clientId || "",
        ChallengeResponses: {
          USERNAME: email,
          ANSWER: otp,
        },
        Session: session,
      };

      cognito.respondToAuthChallenge(params, async (err: any, tokenObject) => {
        if (err !== null && tokenObject === null) {
          setCognitoAPILoading(false);
          setFieldMessage({
            isError: true,
            message: t("passwordLessAuth.invalidOrExpiredCode"),
          });
        } else {
          if (tokenObject?.Session) setSession(tokenObject.Session);
          if (tokenObject.AuthenticationResult?.AccessToken) {
            const companyDetails = await getCompanyDetails(
              tokenObject?.AuthenticationResult?.IdToken,
              email
            );

            const { pullUserInformation } = props;

            identityUserInDynatrace(tokenObject);

           
            await pullUserInformation(
              tokenObject?.AuthenticationResult?.IdToken,
              tokenObject?.AuthenticationResult?.RefreshToken,
              companyDetails?.id,
              HOME,
              true
            );
          } else if (
            tokenObject?.ChallengeParameters &&
            tokenObject?.ChallengeParameters?.attemptsLeft
          ) {
            if (tokenObject?.ChallengeParameters?.isUserActive !== "true") {
              setDisplayAccountLocked(true);
            }
            setAttemptsLeft(Number(tokenObject.ChallengeParameters.attemptsLeft));
            setFieldMessage({
              isError: true,
              message: t("passwordLessAuth.invalidOrExpiredCode"),
            });
            setCognitoAPILoading(false);
          }
        }
      });
    }
  };

  const resendOTP = async () => {
    setOtp("");
    setIsResendOTPEnabled(false);
    setSecondsRemaining(30);

    await saveTheUserAuthEvent({ variables: { email, eventName: eventName.USER_OTP_RESENT, createdBy: eventCreatedBy.SELF } }).then(data => {
      if (data?.data?.saveUserAuthEvent?.content?.userData?.isActive === false) {
        setDisplayAccountLocked(true)
      } else {
        initiateAuth();
      }
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.log(`Error while sending ${eventName.USER_OTP_RESENT} event: `, err);
    });

  };

  const handleChange = (event) => {
    const { value } = event.target;
    if (/^\d{0,6}$/.test(value)) {
      setOtp(value);
      setFieldMessage({ isError: false, message: "" });
    } else {
      setOtp(otp.substring(0, otp.length));
    }
  };

  return (
    <>
      <div className="loginV2 mt-0">
        <Container>
          {(displayGettingUserLoader || displayAccountLocked || displayTrySigningInLater) && <Row className={!isMobile ? "mt-5" : ""} />}
          <Row>
            <Col xs={12} sm={12} md={5} lg={5} className="pb-3 pt-3" />
            {enableMobileAppInfo && <MobileAppQR />}
            <Col xs={12} sm={12} md={6} lg={6} className="pb-3 pt-3">
              <Row className="loginFormV2">
                <Col xs={12} sm={12} md={5} lg={5} />
                <Col
                  xs={12}
                  sm={12}
                  md={7}
                  lg={7}
                  className="pb-3 pt-3 rightsigninform"
                >
                  <div className="genericLogo" />
                  { displayGettingUserLoader && <VerifyAuthView viewType={VerifyAuthViewType.LOADING} /> }
                  { (!displayGettingUserLoader && displayAccountLocked) &&  <VerifyAuthView viewType={VerifyAuthViewType.ACCOUNT_BLOCKED} /> }
                  { (!displayGettingUserLoader && displayTrySigningInLater) &&  <VerifyAuthView viewType={VerifyAuthViewType.TRY_SIGNING_IN_LATER} /> }
                  {(!displayGettingUserLoader && !displayAccountLocked && !displayTrySigningInLater ) && <div>
                  <div className="text-center">
                    <p className={`${styles.heroText} ${styles.heroTextWidth}`}> {(!isEmailVerified) ? t("passwordLessAuth.checkEmailForCode") : t("passwordLessAuth.checkEmailAndMobileForCode")} </p>
                  </div>
                  <p className={styles.infoText}>
                    <span>
                      {t("passwordLessAuth.sentACodeTo")} <br />
                    </span>
                    {getMaskedEmailAndMobile()}
                    <span>{t("passwordLessAuth.enterCodeToSignIn")}</span>
                  </p>
                  <TextField
                    label={t("passwordLessAuth.verificationCode")}
                    variant="outlined"
                    placeholder={t("passwordLessAuth.enterCode")}
                    className="mb-3"
                    value={otp}
                    onChange={handleChange}
                    error={fieldMessage.isError}
                    fullWidth
                    helperText={
                      <span className="fieldMessage">
                        {fieldMessage.isError ? (
                          <div>
                            {" "}
                            <span className="fieldMessage mb40px"> {fieldMessage.message}</span>
                          </div>
                        ) : (
                          <div className="text-muted mb40px">
                            {" "} {t("passwordLessAuth.expireIn")} {" "}
                          </div>
                        )}
                      </span>
                    }
                    inputProps={{
                      maxLength: 6,
                      style: {
                        color: fieldMessage.isError ? "#D7282F" : "#003366",
                        fontWeight: "600",
                      },
                    }}
                  />

                  {(attemptsLeft !== maxAttempts) && (
                    <div className={styles.haveNAttemptsLeft}>
                      <p> {t("passwordLessAuth.youHave")} {attemptsLeft} {t("passwordLessAuth.attemptsLeft")} </p>
                    </div>
                  )}

                  <div className="otpSignIn d-flex justify-content-between align-items-center mt-2">
                    <Button
                      onClick={() => {
                        history.goBack();
                      }}
                      className={styles.backButton}
                    >
                      {" "} {t("passwordLessAuth.back")} {" "}
                    </Button>
                    <Button
                      onClick={submitOTP}
                      disabled={cognitoAPILoading || !isUserPresent}
                      className={`signInButton btn btn-lg text-uppercase text-light ${styles.signInBtn} }`}
                    >
                      <span> {t("passwordLessAuth.signInButton")} </span>
                      {cognitoAPILoading && (
                        <Spinner className="ml-1" animation="border" size="sm" />
                      )}
                    </Button>
                  </div>

                  <div className={`${styles.mt20px} text-center`} >
                    {!isResendOTPEnabled && <p className="p-0 m-0"> {t("passwordLessAuth.weHaveSentAnotherCode")} </p>}
                    {t("passwordLessAuth.didNotReceiveTheCode")}{" "}
                    <button
                      disabled={!isResendOTPEnabled || secondsRemaining > 0}
                      onClick={resendOTP}
                      type="button"
                      className="btn mb-1 p-0 text-primary"
                    >
                      <span className={styles.resendCode}>
                        {" "}
                        {isResendOTPEnabled ? <u> {t("passwordLessAuth.sendANewCode")} </u> : t("passwordLessAuth.resendCodeIn")}
                        {!isResendOTPEnabled && (
                          <span>
                            {" "}
                            00:{secondsRemaining < 10 && 0}
                            {secondsRemaining}{" "}
                          </span>
                        )}{" "}
                      </span>
                    </button>
                  </div>

                  <p className="col-12 text-center">
                    {(isPasswordPresent && isEmailVerified) && (
                      <button
                        type="button"
                        onClick={() => {
                          history.push({
                            pathname: VERIFY_PASSWORD,
                            state: { email },
                          });
                        }}
                        className={`btn ${styles.useMyPasswordInstead} mt-2`}
                      >
                        {t("passwordLessAuth.useMyPasswordInstead")}
                      </button>
                    )}
                  </p>
                  </div> }
                </Col>
                <Col xs={12} sm={12} />
              </Row>
            </Col>
          </Row>
        </Container>
        <div className={styles.LoginFooter}>
          <LowerFooter />
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  pullUserInformation: (idToken, refreshToken, companyId, path, isOTP) =>
    dispatch(saveTokenInLocalStorageAndFillState(idToken, refreshToken, companyId, path, isOTP)),
});
const mapStateToProps = () => {};
export default connect(mapStateToProps, mapDispatchToProps)(VerifyOTPForm);