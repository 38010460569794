export enum VerifyAuthViewType {
    LOADING = "Loading",
    ACCOUNT_BLOCKED = "Account Blocked",
    TRY_SIGNING_IN_LATER = "Try Signing in Later"
}

export enum eventName {
    USER_OTP_RESENT = "USER_OTP_RESENT",
    USER_SIGNED_IN = "USER_SIGNED_IN"
}

export enum eventCreatedBy {
    SELF = "SELF"
}