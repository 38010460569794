export function getRotatingShiftLabel(shift: string): string {
  const shiftType = shift.toLocaleLowerCase();
  if (shiftType.includes("rotating d/e")) {
    const regEx = new RegExp("rotating d/e", "ig");
    return shift.replace(regEx, "Rotating d/m");
  }
  if (shiftType.includes("rotating e/n")) {
    const regEx = new RegExp("rotating e/n", "ig");
    return shift.replace(regEx, "Rotating m/n");
  }

  return shift;
}

export function recruiterConstraint(name: string): boolean {
  const regex = new RegExp("^[a-zA-Z ,.'-]*$");
  return regex.test(name);
}

export const getCognitoUserAttributeValue = (attributeName: string, userAttributes: { Name: string, Value: string }[]) => {
  return userAttributes?.find((attr) => attr.Name === attributeName)?.Value;
};

interface CallWithRetryArgs {
  noOfRetry: number;
  retryDelay: number;
  params: any;
  cb: (userEmail: string) => Promise<boolean>;
}

/**
 * @description Re-execute the function calling API to get response, retry mechanism configurable with below params.
 * @param noOfRetry Number of times the function should be called.
 * @param retryDelay Time interval in milliseconds between retries.
 * @param params an input object passed to the function with some details.
 * @param cb a callback function which will be re-execute as per above configurations, the function is expected to return a boolean, indicating the success of the API call.
 * @returns returns a resolved promise as a boolean flag indicating the overall status of the operation.
 */
export const callWithRetry = async ({ noOfRetry, retryDelay, params, cb }: CallWithRetryArgs) => {
  let didCallSucceed = false;
  for (let i = 0; i < noOfRetry; i++) {
    // eslint-disable-next-line no-await-in-loop
    if (await cb(params?.email)) {
      didCallSucceed = true;
      console.log(`Successful API response in attempts no: ${i+1}`);
      break;
    } else {
      console.log(`Unsuccessful API response in attempt no: ${i+1}`);
      // eslint-disable-next-line no-await-in-loop
      await new Promise(resolve => setTimeout(resolve, retryDelay));
    }
  }
  return Promise.resolve(didCallSucceed);
}

export default { getRotatingShiftLabel, recruiterConstraint ,getCognitoUserAttributeValue, callWithRetry };
