import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useMutation } from "@apollo/client";
import moment from "moment";
import { graphqlRequest as request } from "../../../../services/GraphQL/GraphQL";
import validationsService from "../../../../services/Validations";
import surveyConstraint from "../../../../services/ThankYouReferenceConstraint";
import Analytics from "../../../../services/Analytics";
import {
  SET_CANDIDATE,
  PROFESSION_BY_JOB,
  SPECIALTY_BY_PROFESSION,
} from "../../../../services/GraphQL/graph.query";
import config from "../../../../config";
import { REFERENCE_GENERIC_APPLY, VERIFY_OTP } from "src/router/routes";
import jwt from 'jwt-simple'

const useStyles = makeStyles((theme) => ({
  referThankYouFormFirstRow: {
    display: "flex",
    marginTop: "30px",
    "@media (max-width: 480px)": {
      flexDirection: "column",
    },
  },
  formControlWidth: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    "& .MuiTextField-root, MuiFormControl-root": {
      width: "100%",
    },
  },
  referThankYouFormWrapper: {
    "& #Start-Date-reference": {
      padding: "20px 16px !important",
      "&:focus": {
        padding: "20px 16px !important",
      },
    },
    "& #referThankYouForm": {
      "& label": {
        color: "#00000099",
        backgroundColor: "#fff",
        padding: "0 5px 0 5px",
      },
      "& label.Mui-focused": {
        color: theme.palette.colorPrimary,
      },
      "& label.Mui-focused.Mui-error": {
        color: theme.palette.colorError,
      },
      "& label.MuiInputLabel-shrink": {
        lineHeight: "1rem",
      },
      "&:focus ": {
        color: theme.palette.colorPrimary,
      },
      "& form input": {
        padding: "18px 17px !important",
        "&:focus": {
          border: "2px solid",
          borderColor: theme.palette.colorPrimary,
          borderRadius: "4px",
        },
      },
      "& fieldset": {
        borderColor: "#00000033",
        borderWidth: "2px",
      },
      "& div[class*=Mui-focused]": {
        "& fieldset[class*=MuiOutlinedInput-notchedOutline]": {
          borderColor: theme.palette.colorPrimary,
        },
      },
      "& div[class*=Mui-error]": {
        "& fieldset[class*=MuiOutlinedInput-notchedOutline]": {
          borderColor: `${theme.palette.colorError} !important`,
          borderWidth: "1.8px",
          padding: "0 8px 0 13px",
          top: "-4.6px",
          "& legend span": {
            padding: "0",
          },
        },
      },
    },
    "& .MuiInputBase-inputAdornedEnd": {
      "&:focus": {
        border: "none !important",
      },
    },
    "& .emailTextNormal input": {
      textTransform: "none",
    },
    "& input, .MuiSelect-select": {
      padding: "18px 17px !important",
      color: theme.palette.colorPrimary,
      fontFamily: "inherit",
      fontWeight: "500",
      textAlign: "left",
      textTransform: "capitalize",
      "&:focus": {
        // border: "1.8px solid" + theme.palette.colorPrimary,
        borderRadius: "4px",
        padding: "20px 16px !important",
        height: "19px",
      },
      "&:-webkit-autofill": {
        WebkitBoxShadow: " 0 0 0 30px #ffffff inset !important",
        WebkitTextFillColor: `${theme.palette.colorPrimary} !important`,
      },
    },
    "& .MuiSelect-select.MuiSelect-outlined": {
      color: `${theme.palette.colorPrimary} !important`,
      "&:focus": {
        padding: "18px 17px !important",
        fontWeight: "500",
        fontSize: "0.9rem !important",
      },
    },
    "& .MuiFormHelperText-root": {
      color: theme.palette.colorError,
    },
  },
  dropDownErrorMessageText: {
    color: theme.palette.colorError,
    margin: "3px 14px",
    fontSize: "0.75rem",
    padding: "0px",
    lineHeight: "1.66",
    textAlign: "left",
  },
  menuItem: {
    fontSize: "0.875rem !important",
    padding: "15px 20px !important",
    color: "#707070 !important",
  },
  dropDown: {
    paddingBottom: "30px !important",
    width: "100%",
  },
  referThankYourSubBtn: {
    display: "flex",
    justifyContent: "center",
    "& button": {
      padding: "12px 72px",
      color: "#707070",
      fontWeight: "bold",
      boxShadow: "none",
      borderRadius: "5px",
      fontSize: "1.1rem",
      backgroundColor: theme.palette.colorPrimary,
      fontFamily: "Nunito Sans",
      color: "#fff",
      "&:hover": {
        backgroundColor: theme.palette.colorPrimary,
        color: "#fff",
        boxShadow: "none",
        padding: "12px 72px",
        boxShadow: "0px 4px 2px -2px #00000070",
      },
      "&:disabled ": {
        backgroundColor: "#E2E2E2",
        border: "0",
        color: "#707070",
      },
    },
  },
}));

const ReferenceThankYouForm = ({ handleThankYouSubmitBtn }) => {
  const { search } = useLocation();
  const queryStringValues = search ? queryString.parse(search) : {};
  const { utm_source } = queryStringValues;
  validationsService.init();
  validationsService.setCustomThankYouSurveyValidations();
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const requiredErrMsg = t("onBoarding.errorMessage.required");
  const [setCandidate, { data: candidateData, loading: candidateLoading, error: candidateError }] =
    useMutation(SET_CANDIDATE);
  const [firstName, setFirstName] = useState({
    value: "",
    errors: false,
    errorText: requiredErrMsg,
  });
  const [lastName, setLastName] = useState({
    value: "",
    errors: false,
    errorText: requiredErrMsg,
  });
  const [phoneNumber, setPhoneNumber] = useState({
    value: "",
    errors: false,
    errorText: requiredErrMsg,
  });
  const [emailAddress, setEmailAddress] = useState({
    value: "",
    errors: false,
    errorText: requiredErrMsg,
  });
  const [jobType, setJobType] = React.useState({
    value: "",
    errors: false,
    errorText: requiredErrMsg,
  });
  const [profession, setProfession] = React.useState({
    value: "",
    errors: false,
    errorText: requiredErrMsg,
  });
  const [yrsOfExp, setYrsOfExp] = React.useState({
    value: "",
    errors: false,
    errorText: requiredErrMsg,
  });
  const [yrsInInt, setYrsInInt] = React.useState(null);
  const [professionDesc, setProfessionDesc] = React.useState(null);
  const [specialty, setSpecialty] = React.useState({
    value: "",
    errors: false,
    errorText: requiredErrMsg,
  });
  const [specialtyDesc, setSpecialtyDesc] = React.useState(null);
  const [date, setDate] = React.useState({
    value: "",
    errors: false,
    errorText: requiredErrMsg,
  });
  const [zip, setZip] = useState({
    value: "",
    errors: false,
    errorText: requiredErrMsg,
  });
  const [selectedProfessionObj, setSelectedProfessionObj] = React.useState(null);
  const [selectedSpecialtyObj, setSelectedSpecialtyObj] = React.useState(null);
  const [professionsByJob, setProfessionsByJob] = React.useState([]);
  const [specialtiesByProfession, setSpecialtiesByProfession] = React.useState([]);
  const [company, setCompany] = React.useState(null);
  const [datePickerValue, setDatePickerValue] = React.useState(null);
  const [enableSubmit, setEnableSubmit] = React.useState(false);
  const [professionEnabled, setProfessionEnabled] = useState(false);
  const [specialtyEnabled, setSpecialtyEnabled] = useState(false);
  const [pageLoadTracked, setPageLoadTracked] = React.useState(false);
  const updateObj = (prevObj, fieldValue, validators) => {
    prevObj.value = fieldValue;
    prevObj.errors = !!validators;
    prevObj.errorText = validators ? validators[0] : null;
    return prevObj;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!pageLoadTracked) {
      const segmentEventName = t("segment.pageLoaded");
      const segmentEventProps = {
        URL: document.location.href,
        UTMParameters: {
          utmCampaign: config.REFERENCES_UTM_CAMPAIGN,
          utmSource: utm_source || config.REFERENCES_LEAD_SOURCE,
        },
      };
      trackSegment(segmentEventName, segmentEventProps);
      setPageLoadTracked(true);
    }
  }, [pageLoadTracked]);
  const sortAndDedup = (arrObj, setter) => {
    const temp = [...arrObj];
    temp.sort((a, b) => a.name.localeCompare(b.name));

    const key = "name";
    const arrayUniqueByKey = [...new Map(temp.map((item) => [item[key], item])).values()];

    setter(arrayUniqueByKey);
  };

  const handleJobTypeSelect = (value) => {
    setProfessionEnabled(true);
    setSpecialtyEnabled(false);
    const query = {};
    query.jobType = value;
    query.category = null;
    setSelectedSpecialtyObj(null);
    handleChange(value, "jobType");
    request(PROFESSION_BY_JOB, query).then(
      (res) => {
        const professions = res.professionsByJobType ? res.professionsByJobType.professions : null;
        sortAndDedup(professions, setProfessionsByJob);
      },
      (error) => {
        setProfessionsByJob(null);
        console.error("Error while fetching professions:", error);
      }
    );
  };
  const handleProfessionChange = (professionVal) => {
    setSpecialtyEnabled(true);
    const query = {};
    query.jobType = jobType.value;
    query.profession = professionVal.code;
    query.category = null;
    setSelectedSpecialtyObj(null);
    setSelectedProfessionObj(professionVal);
    setProfessionDesc(professionVal.code);
    handleChange(professionVal.name, "profession");
    request(SPECIALTY_BY_PROFESSION, query).then(
      (res) => {
        const specialties = res.specialtiesByProfession
          ? res.specialtiesByProfession.specialties
          : null;
        sortAndDedup(specialties, setSpecialtiesByProfession);
      },
      (error) => {
        setSpecialtiesByProfession(null);
        console.error("Error while fetching specialties:", error);
      }
    );
  };

  const handleSpecialtyChange = (specialtyVal) => {
    const specialty = specialtyVal.code;
    const company = specialtyVal.company ? specialtyVal.company.code : null;
    setSelectedSpecialtyObj(specialtyVal);
    setCompany(company);
    setSpecialtyDesc(specialtyVal.name);
    handleChange(specialty, "specialty");
  };

  const handleDateChange = async (fieldValue) => {
    if (fieldValue) {
      const startDate = moment(fieldValue).format("MM/DD/YYYY");
      setDatePickerValue(fieldValue);
      handleChange(startDate, "date");
    } else {
      setDatePickerValue(null);
      handleChange(null, "date");
    }
  };

  const handleChange = (value, fieldType) => {
    const fieldValue = value && value.trim() !== "" ? value : "";
    const validators = validationsService.validateSingleField(
      fieldValue,
      surveyConstraint.ThankYouReferenceConstraint[fieldType]
    );
    let obj = {};
    switch (fieldType) {
      case "firstName":
        obj = updateObj(firstName, fieldValue, validators);
        setFirstName((firstName) => ({
          ...firstName,
          ...obj,
        }));
        break;
      case "lastName":
        obj = updateObj(lastName, fieldValue, validators);
        setLastName((lastName) => ({
          ...lastName,
          ...obj,
        }));
        break;
      case "emailAddress":
        obj = updateObj(emailAddress, fieldValue, validators);
        setEmailAddress((emailAddress) => ({
          ...emailAddress,
          ...obj,
        }));
        break;
      case "phoneNumber":
        obj = updateObj(phoneNumber, fieldValue, validators);
        setPhoneNumber((phoneNumber) => ({
          ...phoneNumber,
          ...obj,
        }));
        break;
      case "jobType":
        obj = updateObj(jobType, fieldValue, validators);
        setJobType((jobType) => ({
          ...jobType,
          ...obj,
        }));
        break;
      case "profession":
        obj = updateObj(profession, fieldValue, validators);
        setProfession((profession) => ({
          ...profession,
          ...obj,
        }));
        break;
      case "specialty":
        obj = updateObj(specialty, fieldValue, validators);
        setSpecialty((specialty) => ({
          ...specialty,
          ...obj,
        }));
        break;
      case "yrsOfExp":
        yrsOfExpList.forEach((year, index) => {
          if (fieldValue === year) {
            setYrsInInt(index);
          }
        });
        obj = updateObj(yrsOfExp, fieldValue, validators);
        setYrsOfExp((yrsOfExp) => ({
          ...yrsOfExp,
          ...obj,
        }));
        break;
      case "date":
        obj = updateObj(date, fieldValue, validators);
        setDate((date) => ({
          ...date,
          ...obj,
        }));
        break;
      case "zip":
        obj = updateObj(zip, fieldValue, validators);
        setZip((zip) => ({
          ...zip,
          ...obj,
        }));
        break;
    }
    verifySubmitBtnStatus();
  };

  const jobTypeList = ["Travel", "Per Diem / Local", "Locums", "Permanent"];
  const yrsOfExpList = ["No Experience", "1 Year", "2 Years", "3 Years", "4 Years", "5+ Years"];

  const trackSegment = (segmentEventName, eventProps) => {
    Analytics.segmentTrackEvent(segmentEventName, eventProps);
  };

  const handleSubmitBtn = async () => {
    const json = {
      company,
      dateAvailable: date.value,
      email: emailAddress.value,
      firstName: firstName.value,
      jobType: jobType.value,
      lastName: lastName.value,
      phone: phoneNumber.value,
      professionCode: profession.value,
      professionDesc,
      specialtyCode: specialty.value,
      specialtyDesc,
      experience: yrsInInt,
      utmCampaign: config.REFERENCES_UTM_CAMPAIGN,
      utmSource: utm_source || config.REFERENCES_LEAD_SOURCE,
      utmMedium: config.UTM_MEDIUM
    };
    if (jobType.value === "Per Diem / Local") {
      Object.assign(json, { zip: zip.value });
    }
    try {
      const response = await setCandidate({ variables: { registration: json } });
      if (response) {
        const { appRedirectionURL } = response.data.setCandidate;
        const segmentEventName = t("segment.leadSubmitted");
        const segmentEventProps = {
          job_type: jobType.value,
          profession: profession.value,
          specialty: specialty.value,
          segment_source_name: `${process.env.NODE_ENV}-web-gateway`,
          URL: document.location.href,
          UTMParameters: {
            utmCampaign: config.REFERENCES_UTM_CAMPAIGN,
            utmSource: utm_source || config.REFERENCES_LEAD_SOURCE,
          },
        };
        trackSegment(segmentEventName, segmentEventProps);
        if (appRedirectionURL.includes("http")) {
          if (
            config.ENABLE_EMAIL_VERIFICATION === "true" &&
            appRedirectionURL.includes("auth/redirect")
          ) {
            let payload = { email: emailAddress.value}
            let token = jwt.encode(payload, config.JWT_REDIRECT_SECRET_KEY);

            history.push({
              pathname: `${VERIFY_OTP}/${token}`,
            });
          } else {
            window.open(appRedirectionURL, "_self");
          }
        } else {
          // Candidate created, redirecting to Thank You Screen due to invalid redirection URL
          handleThankYouSubmitBtn(true);
        }
      }
    } catch (error) {
      console.error("Error while creating candidate:", error);
    }
  };
  const verifySubmitBtnStatus = async () => {
    const json = {
      date: date.value,
      emailAddress: emailAddress.value,
      firstName: firstName.value,
      jobType: jobType.value,
      lastName: lastName.value,
      phoneNumber: phoneNumber.value,
      profession: profession.value,
      specialty: specialty.value,
      yrsOfExp: yrsOfExp.value,
    };
    if (jobType.value === "Per Diem / Local") {
      Object.assign(json, { zip: zip.value });
    }
    const validators = validationsService.validateSetOfFields(
      json,
      surveyConstraint.ThankYouReferenceConstraint
    );
    if (validators) {
      setEnableSubmit(false);
    } else {
      setEnableSubmit(true);
    }
  };
  return (
    <>
      <div className={classes.referThankYouFormWrapper}>
        {/* Refer Thank You Form */}
        <div id="referThankYouForm">
          <div className={classes.referThankYouFormFirstRow}>
            <TextField
              variant="outlined"
              label={t("referSurvey.referFirstNameLabel")}
              className={`${classes.formControl} ${classes.dropDown} mr-3`}
              value={firstName.value}
              onChange={(event) => handleChange(event.target.value, "firstName")}
              error={!!firstName.errors}
              helperText={firstName.errors ? firstName.errorText : null}
            />
            <TextField
              label={t("referSurvey.referLastNameLabel")}
              variant="outlined"
              className={`${classes.formControl} ${classes.dropDown} `}
              value={lastName.value}
              onChange={(event) => handleChange(event.target.value, "lastName")}
              error={!!lastName.errors}
              helperText={lastName.errors ? lastName.errorText : null}
            />
          </div>
          <div className={classes.formControlWidth}>
            <TextField
              label={t("referSurvey.referThankYouPhoneLabel")}
              variant="outlined"
              className={classes.dropDown}
              value={phoneNumber.value}
              onChange={(event) => handleChange(event.target.value, "phoneNumber")}
              error={!!phoneNumber.errors}
              helperText={phoneNumber.errors ? phoneNumber.errorText : null}
            />
          </div>
          <div className={classes.formControlWidth}>
            <TextField
              label={t("referSurvey.referThankYouEmailLabel")}
              className={`${classes.dropDown} emailTextNormal`}
              variant="outlined"
              value={emailAddress.value}
              onChange={(event) => handleChange(event.target.value, "emailAddress")}
              error={!!emailAddress.errors}
              helperText={emailAddress.errors ? emailAddress.errorText : null}
            />
          </div>
          <div className={classes.formControlWidth}>
            <FormControl className={classes.dropDown}>
              <InputLabel>{t("referSurvey.referJobTypeLabel")}</InputLabel>
              <Select
                labelId="job-type"
                label={t("referSurvey.referJobTypeLabel")}
                onChange={(event) => handleJobTypeSelect(event.target.value)}
                value={jobType.value}
                error={!!jobType.errors}
                helperText={jobType.errors ? jobType.errorText : null}
              >
                {jobTypeList.map((option) => (
                  <MenuItem key={option} value={option} className={classes.menuItem}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
              {jobType.errors && (
                <span className={classes.dropDownErrorMessageText}>{jobType.errorText}</span>
              )}
            </FormControl>
            <FormControl className={classes.dropDown} disabled={!professionEnabled}>
              <InputLabel id="profession">{t("referSurvey.referProfessionLabel")}</InputLabel>
              <Select
                labelId="profession"
                label={t("referSurvey.referProfessionLabel")}
                onChange={(event) => handleProfessionChange(event.target.value)}
                value={selectedProfessionObj}
                error={!!profession.errors}
                helperText={profession.errors ? profession.errorText : null}
              >
                {professionsByJob.map((option) => (
                  <MenuItem key={option.id} value={option} className={classes.menuItem}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
              {profession.errors && (
                <span className={classes.dropDownErrorMessageText}>{profession.errorText}</span>
              )}
            </FormControl>
            <FormControl className={classes.dropDown} disabled={!specialtyEnabled}>
              <InputLabel id="specialty">{t("referSurvey.referSpecialtyLabel")}</InputLabel>
              <Select
                labelId="specialty"
                className="textLeft"
                label={t("referSurvey.referSpecialtyLabel")}
                onChange={(event) => handleSpecialtyChange(event.target.value)}
                value={selectedSpecialtyObj}
                error={!!specialty.errors}
                helperText={specialty.errors ? specialty.errorText : null}
              >
                {specialtiesByProfession.map((option) => (
                  <MenuItem key={option.id} value={option} className={classes.menuItem}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
              {specialty.errors && (
                <span className={classes.dropDownErrorMessageText}>{specialty.errorText}</span>
              )}
            </FormControl>
            {jobType.value === "Per Diem / Local" && (
              <div className={classes.formControlWidth}>
                <TextField
                  label={t("referSurvey.referZipcodeLabel")}
                  variant="outlined"
                  className={classes.dropDown}
                  value={zip.value}
                  onChange={(event) => handleChange(event.target.value, "zip")}
                  error={!!zip.errors}
                  helperText={zip.errors ? zip.errorText : null}
                />
              </div>
            )}
            <FormControl className={classes.dropDown}>
              <InputLabel id="yrsOfExp">{t("referSurvey.referYrsOfExpLabel")}</InputLabel>
              <Select
                labelId="yrsOfExp"
                className="textLeft"
                label={t("referSurvey.referYrsOfExpLabel")}
                onChange={(event) => handleChange(event.target.value, "yrsOfExp")}
                value={yrsOfExp.value}
                error={!!yrsOfExp.errors}
                helperText={yrsOfExp.errors ? yrsOfExp.errorText : null}
              >
                {yrsOfExpList.map((option) => (
                  <MenuItem key={option} value={option} className={classes.menuItem}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
              {yrsOfExp.errors && (
                <span className={classes.dropDownErrorMessageText}>{yrsOfExp.errorText}</span>
              )}
            </FormControl>
          </div>

          {/* Date */}
          <div className={classes.dropDown}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} value={date.value}>
              <div className={classes.formControlWidth}>
                <KeyboardDatePicker
                  className="datePickerReference"
                  views={["year", "month", "date"]}
                  allowKeyboardControl={false}
                  autoOk="false"
                  inputVariant="outlined"
                  variant="inline"
                  format="MM/dd/yyyy"
                  id="Start-Date-reference"
                  label={t("referSurvey.referStartDateLabel")}
                  minDateMessage={t("onBoarding.basicInfo.minDateMessage")}
                  invalidDateMessage={t("onBoarding.errorMessage.validDateError")}
                  value={datePickerValue}
                  minDate={new Date()}
                  onChange={(event) => handleDateChange(event, "date")}
                  {...(date.errors
                    ? {
                        helperText: date.errorText,
                      }
                    : {})}
                  error={date.errors}
                />
              </div>
            </MuiPickersUtilsProvider>
          </div>
          {/* End Date */}

          <div className={classes.referThankYourSubBtn}>
            <Button variant="contained" onClick={handleSubmitBtn} disabled={!enableSubmit}>
              Submit
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReferenceThankYouForm;
