import { useTranslation } from "react-i18next";
import { VerifyAuthViewType } from "src/types/verifyAuthViewType";
import { CUSTOMER_SERVICE_PHONE } from "src/constants/contact_info";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { LOGIN } from "src/router/routes";
import styles from "./VerifyOTP.module.scss";

interface VerifyAuthViewProp {
  viewType: VerifyAuthViewType;
}

const VerifyAuthView = (props: VerifyAuthViewProp) => {
  const { t } = useTranslation();
  const { viewType } = props;
  const history = useHistory();

  const redirectUserToSignIn = () => {history.push(LOGIN)}

  switch (viewType) {
    case VerifyAuthViewType.LOADING:
      return (
        <div className="loaderContainer">
          <div className={styles.userSpinner} />
          <p className={styles.heroText}> {t("passwordLessAuth.processing")} </p>
          <p className={styles.infoText}> {t("passwordLessAuth.requestProcessing")} </p>
        </div>
      );
    case VerifyAuthViewType.ACCOUNT_BLOCKED:
      return (
        <div>
          <p className={`${styles.heroText}`}>
          {t("passwordLessAuth.accountLocked")}
          </p>
          <p className={styles.infoText}>
            <span>
            {t("passwordLessAuth.pleaseContact")} <b> { CUSTOMER_SERVICE_PHONE } </b> {t("passwordLessAuth.toVerifyIdentity")}
            </span>
          </p>
        </div>
      );

    case VerifyAuthViewType.TRY_SIGNING_IN_LATER:
      return (
        <div className="text-center">
          <p className={`${styles.heroText} text-nowrap`}>{t("passwordLessAuth.takingSomeTime")}</p>
          <p className={styles.infoText}>
            <span>{t("passwordLessAuth.trySignInAfter10Mins")}</span>
          </p>
          <Button
            onClick={redirectUserToSignIn}
            className={`signInButton btn btn-lg mt-3  ${styles.signInBtn} ${styles.mt20px} }`}
          >
            <span> {t("passwordLessAuth.signInButton")} </span>
          </Button>
        </div>
      );

    default:
      return <div> Invalid View Type </div>;
  }
};

export default VerifyAuthView;