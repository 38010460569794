import QRcodeMobileApp from "src/imagesV2/QRcodeMobileApp.svg";
import MobileAppImage from "src/imagesV2/MobileAppImage.png";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const MobileAppQR = () => {
  const { t } = useTranslation();
  return (
    <Row className="desktopQRBanner">
      <Col sm={12} md={12} lg={12} xs={12} className="pb-3 pt-3 d-flex">
        <div>
          <img src={MobileAppImage} alt="" className="mobileAppImage" />
        </div>
        <div className="QRBannerText">
          {" "}
          {t("QRcode.qrCodeMessage")}
          <br />
          {t("QRcode.qrCodeMessageText")}
          <div className="mobileAppImgWrap">
            <img src={QRcodeMobileApp} alt="" className="mobileAppBannerLogo" />
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default MobileAppQR;