import React from "react";
import { withTranslation } from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import Login from "./Login";
import config from "src/config";
import { TFunction } from "react-i18next";
import { History } from "history";
import LowerFooter from "../Footer/LowerFooter/LowerFooter";
import styles from "./AuthV2.module.scss";
import MobileAppQR from "./MobileAppQR";

interface IProps {
  t: TFunction<"translation", undefined>;
  isTokenEmpty: boolean;
  otherProps: {
    history: History;
    location: {
      pathname: string;
      state: {
        needHelpClicked: boolean;
        search: string;
        path: string;
      };
    };
    redirectionPage?: string;
  };
}

interface IState {
  needHelpClicked: boolean;
}

class AuthV2 extends React.Component<IProps, IState> {
  state = {
    needHelpClicked: false,
    isTokenEmpty: localStorage.getItem("isTokenEmpty") || this.props.isTokenEmpty,
  };

  setNeedHelpFlag() {
    if (
      this.props.otherProps.location.state &&
      this.props.otherProps.location.state.needHelpClicked
    ) {
      this.setState({
        needHelpClicked: this.props.otherProps.location.state.needHelpClicked,
      });
    }
  }

  onNeedHelpSignInClickHandler = () => {
    this.setState((prevState) => ({
      needHelpClicked: !prevState.needHelpClicked,
    }));
  };

  render() {
    this.setNeedHelpFlag();
    const enableMobileAppInfo = config.ENABLE_MOBILE_APP_INFO === "true";
    let newUser: boolean;
    const {
      otherProps,
      otherProps: {
        location: { pathname },
        redirectionPage,
      },
    } = this.props;
    const { isTokenEmpty, needHelpClicked } = this.state;
    const sm = 12;

    newUser = pathname !== "/login";
    newUser = false;

    return (
      <>
        <div className="loginV2">
          <Container>
            <Row
              className={
                !newUser
                  ? isTokenEmpty && !needHelpClicked
                    ? "loginHeight duplicateMsgMargin"
                    : "loginHeight"
                  : ""
              }
            />
            <Row>
              <Col xs={sm} sm={sm} md={5} lg={5} className="pb-3 pt-3" />
              { enableMobileAppInfo && <MobileAppQR/> }
              <Col xs={sm} sm={sm} md={6} lg={6} className="pb-3 pt-3">
                <Row className="loginFormV2">
                  <Col xs={sm} sm={sm} md={5} lg={5} />
                  <Col xs={sm} sm={sm} md={7} lg={7} className="pb-3 pt-3 rightsigninform">
                    <Login
                      otherProps={otherProps}
                      needHelpSignIn={this.onNeedHelpSignInClickHandler}
                      needHelpClicked={this.state.needHelpClicked}
                      isTokenEmpty={this.state.isTokenEmpty}
                      newUser={newUser}
                      redirectURL={
                        redirectionPage
                          ? redirectionPage
                          : this?.props?.otherProps?.location?.state?.search &&
                            this.props.otherProps.location.state?.path
                          ? this.props.otherProps.location.state.path +
                            this.props.otherProps.location.state.search
                          : null
                      }
                    />
                  </Col>
                  <Col xs={sm} sm={sm} />
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <div className={styles.LoginFooter}>
          <LowerFooter />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  showResend: state.auth.showResend,
  isTokenEmpty: state.auth.isTokenEmpty,
});

export default connect(mapStateToProps, null)(withTranslation()(AuthV2));
