import moment from "moment";
import Analytics from "src/services/Analytics";
import {
  FETCH_SELECTED_DESTINATIONS,
  SAVE_BASIC_INFO_REQUEST,
  SAVE_BASIC_INFO_SUCCESS,
  SAVE_BASIC_INFO_FAILURE,
  FETCH_STATES_REQUEST,
  FETCH_STATES_SUCCESS,
  FETCH_STATES_FAILURE,
  FETCH_ONBOARDING_INFO_REQUEST,
  FETCH_ONBOARDING_INFO_SUCCESS,
  FETCH_ONBOARDING_INFO_FAILURE,
  UPDATE_COMPLETE_FLAG,
  UPDATE_ACTIVE_FLAG,
  SEND_EMAIL_TO_SUPPORT_TEAM_REQUEST,
  SEND_EMAIL_TO_SUPPORT_TEAM_SUCCESS,
  SEND_EMAIL_TO_SUPPORT_TEAM_FAILURE,
  CLEAR_BASIC_INFO_RESPONSE,
  FETCH_SKILL_CHECKLIST_REQUEST,
  FETCH_SKILL_CHECKLIST_SUCCESS,
  FETCH_SKILL_CHECKLIST_FAILURE,
  FETCH_SKILL_CHECKLIST_BY_ID_REQUEST,
  FETCH_SKILL_CHECKLIST_BY_ID_SUCCESS,
  FETCH_SKILL_CHECKLIST_BY_ID_FAILURE,
  UPDATE_OPTIONAL_SKILLS,
  UPDATE_SKILL_CHECKLIST_STATUS,
  SAVE_OPTIONAL_CHECKLIST_REQUEST,
  SAVE_OPTIONAL_CHECKLIST_SUCCESS,
  SAVE_OPTIONAL_CHECKLIST_FAILURE,
  DELETE_SKILL_CHECKLIST_BY_ID_REQUEST,
  DELETE_SKILL_CHECKLIST_BY_ID_SUCCESS,
  DELETE_SKILL_CHECKLIST_BY_ID_FAILURE,
  SAVE_SKILLS_CHECKLIST_GROUP_SKILLS,
  SAVE_SKILLS_CHECKLIST_GROUP_COMPLETE_STATUS,
  SAVE_SKILLS_CHECKLIST_SELECTED,
  UPDATE_SELECTED_SKILL_COMPLETE_FLAG,
  UPDATED_SKILLS_CHECKLIST,
  FETCH_ALL_SKILL_CHECKLIST_REQUEST,
  FETCH_ALL_SKILL_CHECKLIST_SUCCESS,
  FETCH_ALL_SKILL_CHECKLIST_FAILURE,
  NAVIGATE_TO_SKILL_CHECKLIST_DETAILS_FLAG,
  SAVE_CHECKLIST_IN_ABLE_REQUEST,
  SAVE_CHECKLIST_IN_ABLE_SUCCESS,
  SAVE_CHECKLIST_IN_ABLE_FAILURE,
  SAVE_PROFILE_REQUEST,
  SAVE_PROFILE_SUCCESS,
  SAVE_PROFILE_FAILURE,
  UPDATE_REFERENCES_LOADING_FLAG,
  FETCH_CANDIDATE_CREDENTIALS_REQUEST,
  UPDATE_ONBOARDING_COMPLETED_SECTIONS,
  UPDATE_WORK_EXPERIENCE,
} from "./action";
import localStorageUtil from "../../services/LocalStorageUtil";
import config from "../../config";

const enableReferences = config.ENABLE_REFERENCES === "true";
const completedStepsFromLocalStorage = localStorageUtil.getItem("completedSteps", new Set());
const initialState = {
  error: "",
  loading: false,
  selectedDestinations: [],
  saveBasicInfoResponse: [],
  saveBasicInfoSuccess: false,
  allStates: [],
  referencesLoading: true,
  onboardingInformation: null,
  basicInfo: null,
  specialtiesAndEducation: localStorage.getItem("specialtiesAndEducation")
    ? JSON.parse(localStorage.getItem("specialtiesAndEducation"))
    : null,
  workExperience: localStorage.getItem("workExperience")
    ? JSON.parse(localStorage.getItem("workExperience"))
    : null,
  references: null,
  completedSteps: completedStepsFromLocalStorage,
  hasFetchedCompletedSteps: false,
  activeSteps: localStorage.getItem("activeSteps") ? localStorage.getItem("activeSteps") : -1,
  portalComplete: false,
  triggeringPoint: "onboarding",
  emailLambdaResponse: null,
  skillChecklistInformation: [],
  skillChecklists: [],
  optionalSkillChecklists: [],
  skillChecklistById: [],
  selectedOptionalSkills: [],
  isSkillChecklistComplete: false,
  saveSkillChecklistResponse: [],
  deleteSkillChecklistResponse: [],
  completedSkillsCheckList: localStorage.getItem("completedSkillsCheckList")
    ? JSON.parse(localStorage.getItem("completedSkillsCheckList"))
    : [],
  skillsChecklistGroupSkills: localStorage.getItem("skillsChecklistGroupSkills")
    ? JSON.parse(localStorage.getItem("skillsChecklistGroupSkills"))
    : [],
  skillChecklistSelected: localStorage.getItem("skillChecklistSelected")
    ? JSON.parse(localStorage.getItem("skillChecklistSelected"))
    : "",
  selectedSkillCompleted: localStorage.getItem("selectedSkillCompleted")
    ? JSON.parse(localStorage.getItem("selectedSkillCompleted"))
    : false,
  updatedSkillsChecklist: localStorage.getItem("updatedSkillsChecklist")
    ? JSON.parse(localStorage.getItem("updatedSkillsChecklist"))
    : false,
  isNavigateToSkillChecklistDetails: false,
  saveSkillChecklistInAbleResponse: [],
  completedSections: null,
  lastVisitedPage: null,
  candidateCredentials: [],
  isCredentialsLoading: true,
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SELECTED_DESTINATIONS:
      return { ...state, selectedDestinations: action.payload };

    case SAVE_BASIC_INFO_REQUEST:
      return { ...state, loading: true };

    case SAVE_BASIC_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        saveBasicInfoResponse: action.payload.response.data,
        saveBasicInfoSuccess: true,
        error: "",
        triggeringPoint: action.payload.triggeringPoint,
      };

    case SAVE_BASIC_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        saveBasicInfoSuccess: false,
        error: action.payload.response,
      };

    case FETCH_STATES_REQUEST:
      return { ...state, loading: true, allStates: [] };

    case FETCH_STATES_SUCCESS:
      action.payload.shift();
      return { ...state, loading: false, allStates: action.payload, error: "" };

    case FETCH_STATES_FAILURE:
      return { ...state, loading: false, error: action.payload, allStates: [] };

    case FETCH_ONBOARDING_INFO_REQUEST:
      return { ...state, loading: true, onboardingInformation: null };
      
    case FETCH_ONBOARDING_INFO_SUCCESS: {
      localStorage.setItem(
        "specialtiesAndEducation",
        JSON.stringify(action.payload.response.onboarding.specialtiesAndEducation)
      );
      const workExpSorted = action.payload.response.onboarding.workExperience.sort((a, b) =>
        moment(b.startDate).diff(a.startDate)
      );
      localStorage.setItem("workExperience", JSON.stringify(workExpSorted));
      const completed = {};
      if (
        action.payload.response &&
        action.payload.response.onboarding &&
        action.payload.response.onboarding.meta
      ) {
        if (action.payload.response.onboarding.meta.completedSections.basicInfo) {
          completed[0] = action.payload.response.onboarding.meta.completedSections.basicInfo;
        }
        if (action.payload.response.onboarding.meta.completedSections.specialtiesAndEducation) {
          completed[1] =
            action.payload.response.onboarding.meta.completedSections.specialtiesAndEducation;
        }
        if (action.payload.response.onboarding.meta.completedSections.workExperience) {
          completed[2] = action.payload.response.onboarding.meta.completedSections.workExperience;
        }
        if (action.payload.response.onboarding.meta.completedSections.references) {
          completed[3] = action.payload.response.onboarding.meta.completedSections.references;
        }
        if (action.payload.response.onboarding.meta.completedSections.skillsChecklists) {
          completed[4] = action.payload.response.onboarding.meta.completedSections.skillsChecklists;
        }
        Analytics.fetchProfileCompletion(Object.keys(completed)?.length)
      }
      return {
        ...state,
        loading: false,
        onboardingInformation: action.payload.response,
        basicInfo: action.payload.response.onboarding.basicInfo,
        specialtiesAndEducation: action.payload.response.onboarding.specialtiesAndEducation,
        workExperience: action.payload.response.onboarding.workExperience,
        references: action.payload.response.onboarding.references,
        portalComplete: action.payload.response.isPortalComplete,
        error: null,
        triggeringPoint: action.payload.triggeringPoint,
        completedSteps: completed,
        hasFetchedCompletedSteps: true,
        lastVisitedPage: action.payload.response.onboarding.meta
          ? action.payload.response.onboarding.meta.lastVisitedPage
          : null,
        completedSections: action.payload.response.onboarding.meta
          ? action.payload.response.onboarding.meta.completedSections
          : null,
      };
    }
    case FETCH_ONBOARDING_INFO_FAILURE:
      return { ...state, loading: false, error: action.payload, onboardingInformation: null };

    case UPDATE_COMPLETE_FLAG:
      localStorage.setItem("completedSteps", JSON.stringify(action.payload));
      return { ...state, completedSteps: action.payload };

    case UPDATE_ACTIVE_FLAG:
      localStorage.setItem("activeSteps", action.payload);
      return { ...state, activeSteps: action.payload };

    case SEND_EMAIL_TO_SUPPORT_TEAM_REQUEST:
      return { ...state, loading: true, emailLambdaResponse: null };

    case SEND_EMAIL_TO_SUPPORT_TEAM_SUCCESS:
      return { ...state, loading: false, error: "", emailLambdaResponse: action.payload };

    case SEND_EMAIL_TO_SUPPORT_TEAM_FAILURE:
      return { ...state, loading: false, error: action.payload, emailLambdaResponse: null };
    case CLEAR_BASIC_INFO_RESPONSE:
      return { ...state, saveBasicInfoResponse: null };
    case FETCH_SKILL_CHECKLIST_REQUEST:
      return {
        ...state,
        loading: true,
        skillChecklistInformation: [],
        skillChecklists: [],
        optionalSkillChecklists: [],
      };
    case FETCH_SKILL_CHECKLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        skillChecklistInformation: action.payload,
        skillChecklists: action.payload.onboarding.skillsChecklists,
        optionalSkillChecklists: action.payload.onboarding.skillsChecklistsOptional,
        error: "",
      };
    case FETCH_SKILL_CHECKLIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        skillChecklistInformation: null,
        skillChecklists: [],
        optionalSkillChecklists: [],
      };
    case FETCH_SKILL_CHECKLIST_BY_ID_REQUEST:
      return { ...state, loading: true, skillChecklistById: null };
    case FETCH_SKILL_CHECKLIST_BY_ID_SUCCESS:
      return { ...state, loading: false, skillChecklistById: action.payload.skillsJson, error: "" };
    case FETCH_SKILL_CHECKLIST_BY_ID_FAILURE:
      return { ...state, loading: false, error: action.payload, skillChecklistById: null };
    case UPDATE_OPTIONAL_SKILLS:
      return { ...state, selectedOptionalSkills: action.payload };
    case UPDATE_SKILL_CHECKLIST_STATUS:
      return { ...state, isSkillChecklistComplete: action.payload };
    case SAVE_OPTIONAL_CHECKLIST_REQUEST:
      return { ...state, loading: true, saveSkillChecklistResponse: null };
    case SAVE_OPTIONAL_CHECKLIST_SUCCESS:
        return {
          ...state,
          loading: false,
          saveSkillChecklistResponse: action.payload,
          skillChecklists: action.payload.onboarding.skillsChecklists,
          skillChecklistInformation: action.payload,
          onboardingInformation: action.payload,
          error: "",
        };
    case SAVE_OPTIONAL_CHECKLIST_FAILURE:
      return { ...state, loading: false, error: action.payload, saveSkillChecklistResponse: null };
    case DELETE_SKILL_CHECKLIST_BY_ID_REQUEST:
      return { ...state, loading: true, deleteSkillChecklistResponse: null };
    case DELETE_SKILL_CHECKLIST_BY_ID_SUCCESS:
        return {
          ...state,
          loading: false,
          deleteSkillChecklistResponse: action.payload,
          skillChecklists: action.payload.onboarding.skillsChecklists,
          skillChecklistInformation: action.payload,
          onboardingInformation: action.payload,
          error: "",
        }; 
    case DELETE_SKILL_CHECKLIST_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        deleteSkillChecklistResponse: null,
      };
    case SAVE_SKILLS_CHECKLIST_GROUP_SKILLS:
      localStorage.setItem("skillsChecklistGroupSkills", JSON.stringify(action.payload));
      return { ...state, skillsChecklistGroupSkills: action.payload };
    case SAVE_SKILLS_CHECKLIST_GROUP_COMPLETE_STATUS:
      localStorage.setItem("completedSkillsCheckList", JSON.stringify(action.payload));
      return { ...state, completedSkillsCheckList: action.payload };
    case SAVE_SKILLS_CHECKLIST_SELECTED:
      localStorage.setItem("skillChecklistSelected", JSON.stringify(action.payload));
      return { ...state, skillChecklistSelected: action.payload };
    case UPDATED_SKILLS_CHECKLIST:
      localStorage.setItem("updatedSkillsChecklist", JSON.stringify(action.payload));
      return { ...state, updatedSkillsChecklist: action.payload };
    case UPDATE_SELECTED_SKILL_COMPLETE_FLAG:
      localStorage.setItem("selectedSkillCompleted", JSON.stringify(action.payload));
      return { ...state, selectedSkillCompleted: action.payload };
    case FETCH_ALL_SKILL_CHECKLIST_REQUEST:
      return {
        ...state,
        loading: true,
        skillChecklistInformation: [],
        skillChecklists: [],
        optionalSkillChecklists: [],
      };
    case FETCH_ALL_SKILL_CHECKLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        skillChecklistInformation: action.payload,
        skillChecklists: action.payload.skillsChecklists,
        optionalSkillChecklists: action.payload.skillsChecklistsOptional,
        error: "",
      };
    case FETCH_ALL_SKILL_CHECKLIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        skillChecklistInformation: null,
        skillChecklists: [],
        optionalSkillChecklists: [],
      };
    case NAVIGATE_TO_SKILL_CHECKLIST_DETAILS_FLAG:
      return { ...state, isNavigateToSkillChecklistDetails: action.payload };
    case SAVE_CHECKLIST_IN_ABLE_REQUEST:
      return { ...state, loading: true, saveSkillChecklistInAbleResponse: null };
    case SAVE_CHECKLIST_IN_ABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        saveSkillChecklistInAbleResponse: action.payload,
        error: "",
      };
    case SAVE_CHECKLIST_IN_ABLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        saveSkillChecklistInAbleResponse: null,
      };
    case SAVE_PROFILE_REQUEST:
      return { ...state, loading: true, saveSkillChecklistInAbleResponse: null };
    case SAVE_PROFILE_SUCCESS: {
      const completedStep = {};
      if (action.payload.onboarding.meta.completedSections.basicInfo) {
        completedStep[0] = action.payload.onboarding.meta.completedSections.basicInfo;
      }
      if (action.payload.onboarding.meta.completedSections.specialtiesAndEducation) {
        completedStep[1] = action.payload.onboarding.meta.completedSections.specialtiesAndEducation;
      }
      if (action.payload.onboarding.meta.completedSections.workExperience) {
        completedStep[2] = action.payload.onboarding.meta.completedSections.workExperience;
      }
      if (action.payload.onboarding.meta.completedSections.references) {
        completedStep[3] = action.payload.onboarding.meta.completedSections.references;
      }
      if (action.payload.onboarding.meta.completedSections.skillsChecklists) {
        completedStep[4] = action.payload.onboarding.meta.completedSections.skillsChecklists;
      }

      localStorage.setItem("completedSteps", JSON.stringify(completedStep));
      return {
        ...state,
        loading: false,
        onboardingInformation: action.payload,
        basicInfo: action.payload.onboarding.basicInfo,
        specialtiesAndEducation: action.payload.onboarding.specialtiesAndEducation,
        workExperience: action.payload.onboarding.workExperience,
        references: action.payload.onboarding.references,
        portalComplete: action.payload.isPortalComplete,
        lastVisitedPage: action.payload.onboarding.meta.lastVisitedPage,
        completedSections: action.payload.onboarding.meta.completedSections,
        error: null,
        completedSteps: completedStep,
      };
    }
    case SAVE_PROFILE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case UPDATE_REFERENCES_LOADING_FLAG:
      return { ...state, referencesLoading: action.payload };
    case FETCH_CANDIDATE_CREDENTIALS_REQUEST:
      return { ...state, candidateCredentials: action.payload, isCredentialsLoading: false };
    case UPDATE_ONBOARDING_COMPLETED_SECTIONS:
      return { ...state, completedSteps: action.payload };
    case UPDATE_WORK_EXPERIENCE:
      {
        const updatedWorkExp = { ...state, workExperience: action.payload,onboardingInformation:{
          ...state.onboardingInformation,
          onboarding:{
            ...state.onboardingInformation.onboarding,
            workExperience : action.payload
          }
        } };
        return updatedWorkExp
      }
    default:
      return state;
  }
};

export default rootReducer;
